import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Footer1 from '../components/Footer';
import Header1 from '../components/Header';
import PageBanner from '../components/PageBanner';
import BeforeAfter from "../components/ProjectFilter/BeforeAfter";
import GalleryContent from "../components/ProjectFilter/index"

function Projects() {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <>
            <Header1 />
            <PageBanner bannerBg={`${rpdata?.gallery?.[3]}`} heading="Gallery" currentPage="Gallery" />
            {
                rpdata?.beforeAfterGall?.length > 0 ?
                    <BeforeAfter />
                    : null
            }
            <div className="gall2">
            <div className="content-Gall">
                {
                    rpdata?.landings?.length > 0 ?
                        rpdata?.landings?.map((item, index) => {
                            return (
                                <div className="pt-20 pb-32">
                                    <div className="text-center py-5" key={index}>
                                        <h2 className="text-center">{rpdata?.labels?.general?.titleGallery}</h2>
                                        <h2 className="capitalize">{item.name}</h2>
                                    </div>
                                    <GalleryContent galleryImages={item.gallery} />
                                </div>
                            )
                        })
                        :
                        <div className="pb-32">
                            <div className="text-center py-5">
                                <h2 className="text-center pt-[100px]">{rpdata?.labels?.general?.titleGallery}</h2>
                            </div>
                            <GalleryContent galleryImages={rpdata?.gallery?.length > 0 ? rpdata?.gallery : rpdata?.stock} />
                        </div>
                }
            </div>
            </div>
            <Footer1 />
        </>
    );
}

export default Projects;
